<template>
  <div class="car-list">
    <van-list
      v-if="carList.length > 0"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      :immediate-check="false"
      @load="onLoad"
      class="car-items"
    >
      <div
        v-for="(item, index) in carList"
        :key="index"
        class="car-item"
        @click="toRegister(item)"
      >
        <div class="wrap">
          <h3 class="car-num-plate">{{ item.license_plate_number }}</h3>
          <div class="date right">{{ item.create_time }}</div>
        </div>
        <div class="wrap">
          <div class="goods">{{ item.name_list }}</div>
          <div class="btn right">
            <span>详情</span
            ><img src="../../../assets/images/icon/arrow-r-m.png" alt="" />
          </div>
        </div>
      </div>
    </van-list>
    <empty
      icon="shop"
      text="未获取到车辆信息"
      height="calc(100vh - 3rem)"
      v-else
    />
    <footer-button text="车辆进场" @confirm="confirm" />
  </div>
</template>

<script>
// import moment from 'moment'
import { List } from 'vant'
import FooterButton from '../../../components/footer/button/Index'
import Empty from '../../../components/empty/Index'
import { formatterDate } from '../../../utils'
export default {
  props: {
    getQueryData: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      carList: [],
      finished: false,
      loading: false,
      total: 0,
      sendData: {
        create_time: parseInt((new Date().getTime() - (1000 * 60 * 60 * 24 * 30)) / 1000),
        finifh_time: parseInt(new Date().getTime() / 1000),
        farmers_markt_id: null,
        shop_id: null,
        page_no: 1,
        page_size: 20
      }
    }
  },
  components: {
    [List.name]: List,
    FooterButton,
    Empty
  },
  created () {
    // console.log(this.marketId)
    if (this.getQueryData.marketId) {
      this.sendData.farmers_markt_id = this.getQueryData.marketId
      this.sendData.shop_id = this.getQueryData.shopId
      this.getEntryList()
    }
  },
  methods: {
    onLoad () {
      this.sendData.page_no++
      this.getEntryList()
    },
    // 获取 进场录入 列表 数据
    getEntryList () {
      // console.log('当前请求页数：', this.sendData.page_no)
      this.$api.entry.list(this.sendData).then(res => {
        if (res.status === 200) {
          const data = res.data
          // 加载状态结束
          this.loading = false
          this.total = data.data_total
          if (data.data.length === 0) {
            // 加载结束
            this.finished = true
            return
          }
          let list = []
          list = data.data.map(item => {
            item.create_time = formatterDate('yy-mm-dd hh-mm', item.create_time * 1000)
            // item.create_time = moment(item.create_time * 1000).format('Y-MM-DD HH:mm')
            return item
          })
          this.carList = this.carList.concat(list)
        }
      })
    },
    // 跳转到 入场登记 page
    toRegister (item) {
      // console.log(item, '点击某项进入')
      this.$router.push({
        name: 'EntryRegister',
        query: {
          cartId: item.cart_id,
          isNewGoods: false,
          shopId: item.shop_id,
          shopName: item.shop_name,
          marketMerchantId: this.getQueryData.marketMerchantId,
          marketId: item.farmers_markets_id
        }
      })
    },
    // 提交按钮
    confirm () {
      this.$store.dispatch('setSourceInfo', {})
      this.$router.push({
        name: 'EntryRegister',
        query: {
          cartId: null,
          topActivetab: 0,
          isNewGoods: false,
          ...this.getQueryData
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
