<template>
  <div class="management">
    <div class="top-tabs">
      <div
        class="tab"
        v-for="(tab, index) in topTabs"
        :key="index"
        :class="topActivetab === index ? 'active' : ''"
        @click="handleTopTab(index)"
      >
        <span>{{ tab.text }}</span>
      </div>
    </div>
    <div class="main">
      <div class="st-wrapper">
        <car-list :getQueryData="getQueryData" v-if="topActivetab === 0" />
        <goods-list v-else />
      </div>
    </div>
  </div>
</template>

<script>
import carList from './carList'
import goodsList from './goodsList'
export default {
  data () {
    return {
      topTabs: [{
        name: '/shop/management/car',
        text: '进场录入'
      }, {
        name: '/shop/management/goods',
        text: '商品上架'
      }],
      topActivetab: 0,
      getQueryData: {}
    }
  },
  components: {
    carList,
    goodsList
  },
  created () {
    // 页面传值
    const topActivetab = this.$route.query.topActivetab
    if (topActivetab) {
      this.topActivetab = parseInt(topActivetab)
    }
    // const { marketId, marketMerchantId, shopId, shopName } = this.$route.query
    const queryData = this.$route.query
    if (queryData) {
      this.getQueryData = queryData
    }
  },
  methods: {
    // 切换 顶部tab
    handleTopTab (index) {
      this.topActivetab = index
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
