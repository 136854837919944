<template>
  <div class="goods-list">
    <div>
      <van-tabs
        class="goods-tabs"
        v-model="active"
        color="#1bd49d"
        title-active-color="#1bd49d"
        lazy-render
        @change="hanleTabChange"
      >
        <van-tab title="已上架" name="1">
          <ul class="goods-items" v-if="goodsList.length > 0">
            <li
              v-for="(item, index) in goodsList"
              :key="index"
              class="goods-item"
            >
              <div class="head">
                <h3>{{ item.goods_name }}</h3>
                <!-- <div class="operator">操作者：工作人员</div> -->
                <div class="foot">
                <!-- <div class="date">上架时间：2021-01-06 14:32:36</div> -->
                  <div class="btn" @click="modifyGoods(index)">
                    <span>修改</span>
                  </div>
                </div>
              </div>
              <table class="table">
                <thead>
                  <tr>
                    <th>规格</th>
                    <th>起批量</th>
                    <th>价格</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(good, index1) in item.sku_list" :key="index1">
                    <td
                      class="spec"
                    >
                      {{ good.spec_list[0].spec_value }}{{ good.spec_list[0].spec_name }}
                    </td>
                    <!-- 一个规格内多个价格-->
                    <td class="start-wholesale">
                      {{ good.spec_list[1].spec_value }}{{ good.spec_list[0].spec_name }}起批
                    </td>
                    <td class="price">
                      ￥{{ good.price }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
          </ul>
          <empty
            icon="order"
            text="该商户暂无上架商品"
            height="calc(100vh - 5.5rem)"
            v-else
          ></empty>
        </van-tab>
        <van-tab title="未上架" name="0">
          <ul class="goods-items"  v-if="goodsList.length > 0">
            <li
              v-for="(item, index) in goodsList"
              :key="index"
              class="goods-item"
            >
              <div class="head">
                <h3>{{ item.goods_name }}</h3>
                <!-- <div class="operator">操作者：工作人员</div> -->
                <div class="foot">
                <!-- <div class="date">上架时间：2021-01-06 14:32:36</div> -->
                  <div class="btn" @click="modifyGoods(index)">
                    <span>修改</span>
                  </div>
                </div>
              </div>
              <table class="table">
                <thead>
                  <tr>
                    <th>规格</th>
                    <th>起批量</th>
                    <th>价格</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(good, index1) in item.sku_list" :key="index1">
                    <td
                      class="spec"
                    >
                      {{ good.spec_list[0].spec_value }}{{ good.spec_list[0].spec_name }}
                    </td>
                    <!-- 一个规格内多个价格-->
                    <td class="start-wholesale">
                      {{ good.spec_list[1].spec_value }}{{ good.spec_list[0].spec_name }}起批
                    </td>
                    <td class="price">
                      ￥{{ good.price }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
          </ul>
          <empty
            icon="order"
            text="该商户暂无未上架商品"
            height="calc(100vh - 5.5rem)"
            v-else
          ></empty>
        </van-tab>
      </van-tabs>
    </div>
    <footer-button text="新增商品" @confirm="confirm" />
  </div>
</template>

<script>
import FooterButton from '../../../components/footer/button/Index'
import { Tab, Tabs, Col, Row } from 'vant'
import Empty from '../../../components/empty/Index'
export default {
  inject: ['reload'],
  data () {
    return {
      isShow: false,
      active: 1, // 0代表未上架1代表已上架
      goodsList: [],
      notgoodsList: [],
      sku_list: [],
      page_no: 1,
      page_size: 999
    }
  },
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Col.name]: Col,
    [Row.name]: Row,
    FooterButton,
    Empty
  },
  created () {
    this.getSellerGoodsList(this.active)
  },
  mounted () {
    // window.addEventListener('scroll', this.scroll, false)
  },
  methods: {
    // 提交按钮
    confirm () {
      console.log('提交')
      this.$router.push({ name: 'GoodSell', params: { info: this.$route.query, mode: 0 } })
    },
    modifyGoods (index) {
      this.$router.push({ name: 'GoodSell', params: { info: this.goodsList[index], mode: 1 } })
    },
    hanleTabChange (e) {
      this.page_no = 1
      this.goodsList = []
      this.active = e
      this.getSellerGoodsList(this.active)
    },
    getSellerGoodsList (currentTab) {
      const tmp = this.$route.query.shopId
      const param = {
        flag: currentTab,
        page_no: this.page_no,
        page_size: this.page_size,
        seller_id: tmp
      }
      this.$api.goods.goodsList(param).then((res) => {
        this.goodsList = res.data.data
        console.log(this.goodsList)
      })
    },
    unitToCN (str) {
      var _str = !str ? '' : str
      var unitObj = {
        KILO: '公斤',
        JIN: '斤',
        GRAMME: '克',
        TAEL: '两'
      }
      return unitObj[_str] ? unitObj[_str] : _str
    }
    // scroll () {
    //   const active = this.active
    //   const cardSection = document.getElementsByClassName('goods-items')
    //   const scroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 页面滚动高度
    //   const windowHeight = window.innerHeight // 窗口高度
    //   const index = 0
    //   const cardSectionTop = cardSection[index].offsetTop // card_section距离顶部的偏移高度（card_section为你的照片或div元素ID）
    //   const cardSectionHeight = cardSection[index].offsetHeight // card_section的高度
    //   if (cardSectionTop + cardSectionHeight < scroll + windowHeight - 158) {
    //     this.page_no++
    //     this.getSellerGoodsList(active)
    //   }
    // }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
